import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MarketCategoryButton from "./MarketCategoryButton";
import {
  startGetProducts,
  startGetCauses,
  startAddProducts,
  startAddCauses,
} from "../../redux/Market/market.actions";
import ProductCard from "./ProductCard";
import CauseCard from "./CauseCard";
import BePartOf from "./BePartOf";
import { useTranslation } from "react-i18next";

export const MarketBody = ({
  startGetProductsData,
  startGetCausesData,
  productsArray,
  causesArray,
  productsNextPageUrl,
  causesNextPageUrl,
  selectedCategoryData,
  selectedCategoryName,
  goodsOrCauses,
  dispatchStartAddProducts,
  dispatchStartAddCauses,
  closeSearchDiv,
}) => {
  const { t } = useTranslation();
  const [loadMoreState, setLoadMoreState] = React.useState({
    causeLoadMore: false,
    productLoadMore: false,
  });

  const history = useHistory();
  
  const handleCategoryChange = (category) => {
    if (category === "causes") {
      history.push("?category=causes");
    } else if (category === "goods") {
      history.push("?category=products");
    }
  };

  React.useEffect(() => {
    if (goodsOrCauses === "causes") {
      startGetCausesData();
    } else if (goodsOrCauses === "goods") {
      startGetProductsData();
    }
    updateLoadMore(goodsOrCauses);
  }, [goodsOrCauses, startGetCausesData, startGetProductsData]);

  const updateLoadMore = (state) => {
    if (causesNextPageUrl && state === "causes") {
      setLoadMoreState({ causeLoadMore: true, productLoadMore: false });
    } else if (productsNextPageUrl && state === "goods") {
      setLoadMoreState({ causeLoadMore: false, productLoadMore: true });
    } else {
      setLoadMoreState({ causeLoadMore: false, productLoadMore: false });
    }
  };

  const loadMore = () => {
    if (goodsOrCauses === "goods" && productsNextPageUrl) {
      dispatchStartAddProducts(productsNextPageUrl);
    } else if (goodsOrCauses === "causes" && causesNextPageUrl) {
      dispatchStartAddCauses(causesNextPageUrl);
    }
  };

  const renderCards = () => {
    if (selectedCategoryData && selectedCategoryName) {
      if (goodsOrCauses === "goods" && Array.isArray(selectedCategoryData)) {
        return selectedCategoryData.map((data, index) => (
          <ProductCard key={index} item={data} />
        ));
      } else if (goodsOrCauses === "causes" && Array.isArray(selectedCategoryData)) {
        return selectedCategoryData.map((data, index) => (
          <CauseCard key={index} item={data} />
        ));
      }
    } else {
      if (goodsOrCauses === "goods" && Array.isArray(productsArray)) {
        return productsArray.map((data, index) => (
          <ProductCard key={index} item={data} />
        ));
      } else if (goodsOrCauses === "causes" && Array.isArray(causesArray)) {
        return causesArray.map((data, index) => (
          <CauseCard key={index} item={data} />
        ));
      }
    }
    return null;
  };

  return (
    <div className="bg-bodyBg p-8 rounded-tl-bodyxl rounded-tr-bodyxl" onClick={closeSearchDiv}>
      <div className="flex flex-row w-full items-center justify-center gap-x-12 mb-8">
      <MarketCategoryButton
          onClick={() => {
            handleCategoryChange("causes");
            setLoadMoreState({ causeLoadMore: true, productLoadMore: false });
          }}
          type="causes"
          active={goodsOrCauses === "causes"}
        />
        <MarketCategoryButton
          onClick={() => {
            handleCategoryChange("goods");
            setLoadMoreState({ causeLoadMore: false, productLoadMore: true });
          }}
          type="products"
          active={goodsOrCauses === "goods"}
        />
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-auto gap-4">
        {renderCards()}
      </div>

        <div
          className="w-full text-center text-primary underline py-2 btn-effects font-LatoRegular mt-10"
          onClick={loadMore}
        >
          {t('profile.load_more')}
        </div>

      <BePartOf />
    </div>
  );
};

const mapState = ({ market }) => ({
  causesArray: market.causesArray,
  productsArray: market.productsArray,
  causesNextPageUrl: market.causesNextPageUrl,
  productsNextPageUrl: market.productsNextPageUrl,
  selectedCategoryData: market.selectedCategoryData,
});

const mapDispatchToProps = (dispatch) => ({
  startGetCausesData: () => dispatch(startGetCauses()),
  startGetProductsData: () => dispatch(startGetProducts()),
  dispatchStartAddCauses: (pageurl) => dispatch(startAddCauses(pageurl)),
  dispatchStartAddProducts: (pageurl) => dispatch(startAddProducts(pageurl)),
});

export default connect(mapState, mapDispatchToProps)(MarketBody);
